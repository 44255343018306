import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Process1 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_01.jpg'
import Process2 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_02.jpg'
import Process3 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_03.jpg'
import Process4 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_04.jpg'
import Process5 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_05.jpg'
import Process6 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_06.jpg'
import Process7 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_07.jpg'
import Process8 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_08.jpg'
import Process9 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_09.jpg'
import Process10 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_10.jpg'
import Process11 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_11.jpg'
import Process12 from '../../../images/work/12_PG_HILLCREST/PROCESS/HIL_12.jpg'
import Process1s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_01.jpg'
import Process2s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_02.jpg'
import Process3s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_03.jpg'
import Process4s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_04.jpg'
import Process5s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_05.jpg'
import Process6s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_06.jpg'
import Process7s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_07.jpg'
import Process8s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_08.jpg'
import Process9s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_09.jpg'
import Process10s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_10.jpg'
import Process11s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_11.jpg'
import Process12s from '../../../images/work/12_PG_HILLCREST/PROCESS/450X280/HIL-SM_12.jpg'

const Residence12 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="PACIFIC GROVE RESIDENCE"
        residenceLocation="PACIFIC GROVE, CALIFORNIA"
        infoList={['Remodel of existing 1,000 SF single family residence. Extension of the main floor and 700 SF upper level addition to a total floor area of 1,850 SF.', 'Design - 2017', 'Construction - 2018', 'General Contractor: Icon Construction, Inc', 'Structural Engineer: Structures Engineering, Inc']}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[Process1,Process2,Process3,Process4,Process5,Process6,Process7,Process8,Process9,Process10,Process11,Process12]}
        processPhotosSmall={[Process1s,Process2s,Process3s,Process4s,Process5s,Process6s,Process7s,Process8s,Process9s,Process10s,Process11s,Process12s]}
        />
    </Container>
  </Layout>
)

export default Residence12;
