import React from 'react';

import './style.css';

import Video from '../video';

const ResidenceDetails = (props) => (
  <section className="work-residence">
    <article className="work-residence-title">
      <h3>{props.residenceTitle}</h3>
      <p>{props.residenceLocation}</p>
    </article>

    <section className="work-residence-content">
      <article className="work-residence-text">
        <article>
          <h4>PROJECT INFO:</h4>
          {props.infoList.map((info) => {
            return <p key={info}>{info}</p>;
          })}
        </article>

        <article>
          {props.testimonialList.length !== 0 && <h4>TESTIMONIAL:</h4>}
          {props.testimonialList.length !== 0 &&
            props.testimonialList.map((t) => {
              return <p key={t}>{t}</p>;
            })}
        </article>
      </article>

      <article className="work-residence-videos">
        {props.videos &&
          props.videos.length > 0 &&
          props.videos.map((p, i) => {
            return (
              <article k={p}>
                <Video videoSrcURL={p}></Video>
              </article>
            );
          })}
      </article>

      <article className="work-residence-images">
        {props.photos.map((p, i) => {
          return (
            <article key={props.photosSmall[i]}>
              <a
                href={p.type != null ? p.url : p}
                className={p.type != null ? 'video' : ''}
                target={p.type != null ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <img
                  src={props.photosSmall[i]}
                  alt={`The ${i} workcase photograph`}
                ></img>
              </a>
            </article>
          );
        })}
        {props.processPhotos.length > 0 && (
          <h3 className="process-text">PROCESS</h3>
        )}
        {props.processPhotos.map((p, i) => {
          return (
            <article key={props.processPhotosSmall[i]}>
              <a
                href={p.type != null ? p.url : p}
                className={p.type != null ? 'video' : ''}
                target={p.type != null ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <img
                  src={props.processPhotosSmall[i]}
                  alt={`Showcase photograph`}
                ></img>
              </a>
            </article>
          );
        })}
      </article>
    </section>
  </section>
);

export default ResidenceDetails;
