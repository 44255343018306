import React from 'react';

import './style.css';

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <iframe
    src={videoSrcURL}
    title="Video example"
    frameBorder="0"
    allowFullScreen
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
  />
);

export default Video;
